import { localStorageSET, localStorageGET } from '@/utils/local'
import zh from './zh'
import en from './en'
import zhCn from './zh-cn'

let langVal = localStorageGET("lang")
if(!langVal){
    langVal = document.documentElement.setAttribute("lang", "zh-cn")
    localStorageSET({
        "lang": "zh-cn"
    })
} else {
    langVal = document.documentElement.setAttribute("lang", langVal)
}

export default {
    zh,
    en,
    'zh-cn': zhCn
}
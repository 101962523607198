import {
    getAction,
    postAction,
    uploadData
} from '@/utils/axios'

// 登录
export const login = (params) => postAction('sys/login', params);

// 创建用户
export const regisiter = (params) => postAction('sys/createUser', params);

// 删除用户
export const deleteUser = (params) => postAction('sys/deleteUser', params);

// 修改密码
export const modifyPass = (params) => postAction('sys/modifyPass', params);

// 验证token有效性
export const verifyToken = (params) => postAction('sys/verifyToken', params);

// 获取APP列表
export const getAppList = (params) => postAction('siteInfo/getAppList', params);

// 删除app
export const deleteApp = (params) => postAction('siteInfo/deleteApp', params);

// 获取页面配置
export const getSiteConfigInfo = (params) => postAction('siteInfo/getConfigInfo', params);

// 修改页面配置
export const editSiteConfig = (params) => postAction('siteInfo/editSiteConfig', params);

// 新增配置文件
export const createSiteConfig = (params) => postAction('siteInfo/createSiteConfig', params);

// 获取app统计数据
export const getAppCount = (params) => getAction('siteInfo/getAppCount', params);

// 获取用户列表
export const getUserList = (params) => postAction('sys/getUserList', params);

// 续期
export const renewMembership = (params) => postAction('sys/renewMembership', params);

// 获取所有代理统计数据
export const getReport = (params) => getAction('siteInfo/getReport', params);

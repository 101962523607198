import axios from 'axios'
import {ElNotification, ElMessage } from 'element-plus'
import {
    getToken,
    removeToken
} from './auth'
// import {getCurrentInstance } from 'vue'

// const { appContext } = getCurrentInstance();
// const $Lang = appContext.config.globalProperties.$Lang;


const API = axios.create({
    timeout: 3000,
    //   baseURL: "/foo",
    baseURL: process.env.VUE_APP_URL,
    headers: {
        'content-type': 'application/json; charset=UTF-8',
        Accept: 'application/json'
    }
})

// 请求拦截（请求发送之前触发）
API.interceptors.request.use((config) => {
    const {
        url
    } = config
    if (!url.startsWith('/user/login')) {
        // 添加请求头
        config.headers['X-Access-Token'] = getToken()
    }
    return config
}, (error) => {
    return Promise.reject(error)
})

// 响应拦截（后台返回触发）
API.interceptors.response.use((response) => {
    const {
        code
    } = response.data
    if (code === 401) {
        ElNotification.error({
            title: 'Error',
            message: 'Login information invalid~',
            type: 'error',
        })
        setTimeout(() => {
            removeToken()
            localStorage.setItem('userInfo', '');
            window.location.href = '/#/login'
        }, 500);
    }
    return response.data
}, (error) => {
    console.info(777, error)
    if (error.response) {
        switch (error.response.code) {
            case 401:
                setTimeout(() => {
                    removeToken()
                    localStorage.setItem('userInfo', '');
                    window.location.href = '/#/login'
                }, 500);
                break
            case 404:
                console.log('请求路径不存在')
            default:
                ElMessage.error('error')
                break
        }
    }
})

// get
export function getAction(url, params) {
    return API({
        url,
        method: 'get',
        params
    })
}

// post
export function postAction(url, params) {
    return API({
        url,
        method: 'post',
        data: params
    })
}

// 上传图片
export function uploadData(url, params) {
    return API({
        url,
        method: 'post',
        data: params,
        headers: {
            'Content-Type': 'multipart/form-data', // Or other content type if needed
        },
    })
}

export default {
    common: {
        confirm: "确认",
        cancel: "取消",
        count: "次",
        add: "新增",
        edit: "编辑",
        download: "下载",
        del: "删除",
        deleteTip: "确认删除？",
        status: "状态",
        availTime: "套餐到期",
        tokenInvalid: "登录信息失效~",
        availToolTip: "套餐已过期，请联系代理",
    },
    login: {
        out: "退出登录",
        outConfirm: "确认退出登录吗？",
        outSucceed: "退出登录成功",
        loginTitle: "用户登录",
        userName: "用户名",
        password: "密码",
        login: "登录",
        regisiterAccount: "注册账号",
        regisiterSuccess: "注册成功",
        regisiter: "注册",
        changePassword: "修改密码",
        oldPwd: "旧密码",
        newPwd: "新密码",
        confirmPwd: "再次输入新密码",
        changeSuccess: "修改成功",
        
    },
    home: {
        region: "国家地区",
        openCount: "页面打开次数",
        downloadCount: "下载次数",
        deliveryTime: "投放时间",
        total: "总计",
    },
    user: {
        userManager: "用户管理",
    },
    fundWallet: {
        me: "我的钱包",
        recharge: "充值",
        expireDate: "到期时间",
        renew: "续费",
    },
    menu: {
        statisticalAnalysis: "统计分析",
        home: "首页",
        popularize: "推广",
        develop: "开发中心",
        finance: "财务"
    },
    menuSub: {
        documentCenter: "文档中心",
        fundWallet: "资金钱包",
        appManage: "APP 管理",
        pixelManage: "像素管理"
    },
    languages: {
        "alter": "语言切换至",
        "zh-cn": "中文(简体)",
        "zh": "中文(繁体)",
        "en": "英语",
        "id": "印尼语",
        "ja": "日语",
        "fr": "法语",
        "vi": "越南语",
        "ru": "俄语",
        "de": "德语",
        "es": "西班牙语",
    },
    preview: {
        download: "下载",
        comment: "评论",
        age: "年龄",
        rank: "排行榜",
        developer: "开发者",
        unitAge: "岁",
        newFunction: "新功能",
        versionHistory: "版本历史记录",
        ver: "版本",
        preview: "预览",
        scoreAndComment: "评分于评论",
        fullScore: "满分",
        clickToScore: "评论",
        information: "信息",
        category: "类别",
        compatibility: "兼容性",
        size: "大小",
        language: "语言",
        ageGrading: "年龄分级",
        offer: "提供者",
        copyright: "版权",
    },
    appManage: {
        appManager: "管理",
        name: "名称",
        icon: "图标",
        share: "推广页",
        pageLanguage: "推广页语言",
        remark: "备注",
        operation: "操作",
        baseMsg: "打包信息",
        companyName: "公司名称",
        ageLimit: "年龄限制",
        commentNumber: "评论数",
        packSize: "包大小",
        category: "类型",
        rank: "排名",
        version: "版本号",
        updateTime: "更新时间",
        updateConten: "更新内容",
        descript: "简介",
        previeTip: "请上传App简介",
        scoreSet: "打分设置",
        finalScore: "最终得分",
        commentSet: "评论设置",
        addComment: "添加评论",
        cancel: "取消",
        nextStep: "下一步",
        upStep: "上一步",
        package: "打包",
        address: "地址",
        shopMsg: "商店信息",
        nameDesc: "名称描述",
        imgTip: "支持 png/jpg/jepg 格式的图片，图片最大2MB",
        imgErrorTip1: "图片大小不能超过 2MB!",
        imgErrorTip2: "请上传 jpg/png/jpeg 类型的文件!",
        language: "语言",
        languageTip: "请选择APP内支持语言",
        compatibility: "兼容性",
        compatibilityTip: "请选择APP兼容性",
        copyright: "版权",
        detailPics: "详情图",
        score: "分数1-5",
        date: "日期",
        content: "内容",
        placeholderTip1: "请输入App名字",
        placeholderTip2: "请上传APP图标",
        placeholderTip3: "请输入APP名称描述",
        placeholderTip4: "请输入公司名字",
        placeholderTip5: "请输入APP年龄限制",
        placeholderTip6: "请输入App排名",
        placeholderTip7: "请输入App包大小",
        placeholderTip8: "请输入App分类",
        placeholderTip9: "请选择App语言",
        placeholderTip10: "请输入App版本号",
        placeholderTip11: "请输入版本更新时间",
        placeholderTip12: "请输入版本更新内容",
        placeholderTip13: "请输入App描述",
        placeholderTip14: "请输入版权信息",
        placeholderTip15: "请至少选择一个",
        placeholderTip16: "请输入打包地址",
        placeholderTip17: "请输入App评论数",
        finishPack: "打包完成！",
        confirm: "确认",
        success: "成功",
        tip: "提示",
        deleteTip: "确认删除该APP?",
        packing: "正在打包...",
        placeholderNormal: "请输入",
        deleteCommmentTip: "确认删除该评论?",
        digitsPlaceholder: "请输入数字",
        digitsRangePlaceholder: "请输入1-5之间的分数",
        addAppTip: "每个账号只能创建一个APP",
    },
    pixelManage: {
        adPixel : "广告像素",
        pixelName : "像素名称"
    },
    documentCenter: {
        documentCenter: "文档中心"
    }
}
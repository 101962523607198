/**
    获取对象的值（多级）
    Author:kcliao
*/
export const getValueFromObject = (obj, keyString) => {
    let keyArr = keyString.split('.'), result = obj;
    keyArr.forEach((item) => {
        result = result[item]
    })
    
    return result
}

// 计算时间差

export const datediff = (interval, date1, date2) => {
    let i = {}, t = date1.getTime(), t2 = date2.getTime();
    i['y'] = date2.getFullYear() - date1.getFullYear();
    i['q'] = i['y'] * 4 + Math.floor(date2.getMonth() / 4) - Math.floor(date1.getMonth() / 4);
    i['m'] = i['y'] * 12 + date2.getMonth() - date1.getMonth();
    i['w'] = Math.floor((t2 + 345600000) / (604800000)) - Math.floor((t + 345600000) / (604800000));
    i['d'] = Math.floor(t2 / 86400000) - Math.floor(t / 86400000);
    i['h'] = Math.floor(t2 / 3600000) - Math.floor(t / 3600000);
    i['n'] = Math.floor(t2 / 60000) - Math.floor(t / 60000);
    i['s'] = Math.floor(t2 / 1000) - Math.floor(t / 1000);
    i['ms'] = date2.getTime() - date1.getTime();
    return i[interval];
}
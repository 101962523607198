export default {
    common: {
        confirm: "Confirm",
        cancel: "Cancel",
        count: "count",
        add: "Add ",
        edit: "Edit",
        download: "Download",
        del: "Delete",
        deleteTip: "Confirm deletion?",
        status: "Status",
        availTime: "Package Expiration",
        tokenInvalid: "Login information invalid~",
        availToolTip: "The package has expired, please contact the agent",
    },
    login: {
        out: "Log out",
        outConfirm: "Are you sure you want to log out?",
        outSucceed: "Logout successful",
        loginTitle: "User login",
        userName: "username",
        password: "password",
        login: "Log In",
        regisiterAccount: "Register Account",
        regisiterSuccess: "Registration Success",
        regisiter: "Register",
        changePassword: "Change Password",
        oldPwd: "Old Password",
        newPwd: "New Password",
        confirmPwd: "Enter the new password again",
        changeSuccess: "Successfully modified",
    },
    home: {
        region: "Country",
        openCount: "Page Open Count",
        downloadCount: "Download Times",
        deliveryTime: "Delivery Time",
        total: "Total"
    },
    user: {
        userManager: "User Manager",
    },
    fundWallet: {
        me: "My wallet",
        recharge: "recharge",
        expireDate: "Expiration time",
        renew: "Renewal",
    },
    menu: {
        statisticalAnalysis: "Statistical Analysis",
        home: "Home",
        popularize: "Popularize",
        develop: "Development Center",
        finance: "Finance"
    },
    menuSub: {
        documentCenter: "Document Center",
        fundWallet: "Wallets for funds",
        appManage: "APP Manager",
        pixelManage: "Pixel Management"
    },
    languages:{
        "alter": "Language switch to ",
        "zh-cn": "Chinese (Simplified)",
        "zh": "Chinese (Traditional)",
        "en": "English",
        "id": "Indonesian",
        "ja": "Japanese",
        "fr": "French",
        "vi": "Vietnamese",
        "ru": "Russian",
        "de": "German",
        "es": "Spanish"
    },
    preview: {
        download: "Download",
        comment: "Comment",
        age: "Age",
        rank: "Ranking list",
        developer: "Developer",
        unitAge: "Years old",
        newFunction: "New function",
        versionHistory: "Version history",
        ver: "version",
        preview: "Preview",
        scoreAndComment: "Ratings and comments",
        fullScore: "Full score",
        clickToScore: "Click to rate",
        information: "Message",
        category: "Category",
        compatibility: "Compatibility",
        size: "size",
        language: "language",
        ageGrading: "ageGrading",
        offer: "provider",
        copyright: "copyright",
    },
    appManage: {
        appManager: "Manager",
        add: "Add ",
        edit: "Edit",
        download: "Download",
        del: "Delete",
        name: "Name",
        icon: "Icon",
        share: "Promotion",
        pageLanguage: "Promotion Page Language",
        remark: "Remark",
        operation: "Operation",
        baseMsg: "Package Message",
        companyName: "Company Name",
        ageLimit: "Age Limit",
        commentNumber: "Comment Number",
        packSize: "Pack Size",
        category: "Category",
        rank: "Rank",
        version: "Version Number",
        updateTime: "Update Time",
        updateConten: "Update Content",
        descript: "Descript",
        scoreSet: "Score Set",
        finalScore: "Final Score",
        commentSet: "Comment Set",
        addComment: "Add Comment",
        cancel: "cancel",
        nextStep: "next step",
        upStep: "previous",
        package: "package",
        address: "address",
        shopMsg: "Shop Message",
        nameDesc: "Name Description",
        imgTip: "Supports png/jpg/jpeg format images, image size up to 2MB",
        imgErrorTip1: "Image size cannot exceed 2MB!",
        imgErrorTip2: "Please upload files in jpg/png/jpeg format!",
        language: "Language",
        languageTip: "Please select the supported languages in the APP",
        compatibility: "Compatibility",
        compatibilityTip: "Please select APP compatibility",
        copyright: "Copyright",
        detailPics: "Detail Pictures",
        score: "Score 1-5",
        date: "Date",
        content: "Content",
        placeholderTip1: "Please enter the App name",
        placeholderTip2: "Please upload the APP icon",
        placeholderTip3: "Please enter the APP name description",
        placeholderTip4: "Please enter the company name",
        placeholderTip5: "Please enter the APP age restriction",
        placeholderTip6: "Please enter the App ranking",
        placeholderTip7: "Please enter the App package size",
        placeholderTip8: "Please enter the App category",
        placeholderTip9: "Please select the App language",
        placeholderTip10: "Please enter the App version number",
        placeholderTip11: "Please enter the version update date",
        placeholderTip12: "Please enter the version update content",
        placeholderTip13: "Please enter the App description",
        placeholderTip14: "Please enter the copyright information",
        placeholderTip15: "Please select at least one",
        placeholderTip16: "Please enter the package address",
        placeholderTip17: "Please enter the number of App reviews",
        finishPack: "Packaging complete!",
        confirm: "Confirm",
        success: "Success",
        tip: "Tip",
        deleteTip: "Are you sure you want to delete this APP?",
        packing: "Packing...",
        placeholderNormal: "Please enter",
        deleteCommmentTip: "Are you sure you want to delete this comment?",
        digitsPlaceholder: "Please enter the number",
        digitsRangePlaceholder: "Please enter a score between 1-5",
        addAppTip: "Each account can only create one APP",
    },
    pixelManage: {
        adPixel : "Advertising pixel",
        pixelName : "Pixel Name"
    },
    documentCenter: {
        documentCenter: "Documentation Center"
    }
}
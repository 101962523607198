const domainName = "apply"

// 写入localstorage，可同时写入多个
export const localStorageSET = (keys, cb) => {
    let local = localStorage.getItem(domainName);
    if (local === null || local === undefined || local === "") {
        // 没有本地缓存的会后初始化本地缓存
        localStorage.setItem(
            domainName,
            JSON.stringify({
                lang: 'zh',
            })
        );
        localStorageSET(keys, cb);
    } else {
        local = JSON.parse(local);
        Object.getOwnPropertyNames(keys).forEach((item) => {
            local[item] = keys[item];
        })
        localStorage.setItem(domainName, JSON.stringify(local));
        localStorageGET();
    }
}

// 读取localstorage
export const localStorageGET = (key, cb) => {
    let local = localStorage.getItem(domainName),
        result = "";
    if (local === null || local === undefined || local === "") return "";
    local = JSON.parse(localStorage.getItem(domainName));
    if (key === undefined) {
        result = local;
        // cfg.localStorage = result;
    } else {
        result = local[key];
        // cfg.localStorage[key] = result;
    }
    return result;
}

// 移除localstorage，可同时移除多个
export const localStorageREMOVE = (keys) => {
    let local = localStorage.getItem(domainName);
    local =
        local === null ?
            {} :
            JSON.parse(localStorage.getItem(domainName));
            keys.forEach((item) => {
                delete local[item];
            })
    localStorage.setItem(domainName, JSON.stringify(local));
    localStorageGET();
}
<template>
    <div class="main">
        <LeftMenu :isCollapse="isCollapse"></LeftMenu>
        <div class="main-right">
            <div class="header">
                <div class="menu-btn">
                    <el-icon @click="menuToggle">
                        <Expand v-if="isCollapse" />
                        <Fold v-else />
                    </el-icon>
                </div>
                <div class="user-content">
                    <!-- <div class="avail-time">
                        <div class="title">{{ $Lang("common.availTime") }}：</div>
                        <div :style="{color: isAvaild ? '#000' : '#000'}">{{ userInfo.availTime }}</div>
                        <el-tooltip
                            class="box-item"
                            effect="dark"
                            :content="`${$Lang('common.availToolTip')}`"
                            placement="bottom"
                        >
                            <el-icon class="avail-icon" v-if="isAvaild" ><info-filled /></el-icon>
                        </el-tooltip>
                    </div>
                    <el-dropdown class="lang-dropdown" @command="handleCommand">
                        <span class="el-dropdown-link">
                        {{ langValue }}<el-icon class="el-icon--right"><arrow-down /></el-icon>
                        </span>
                        <template #dropdown>
                        <el-dropdown-menu>
                            <el-dropdown-item v-for="(item, key) in langData" v-bind:key="key" :command="key">{{item.label}}</el-dropdown-item>
                        </el-dropdown-menu>
                        </template>
                    </el-dropdown> -->

                    <el-popover
                        placement="top-start"
                        title=""
                        :width="160"
                        popper-style="padding: 0"
                        trigger="hover"
                    >
                        <slot name="content">
                            <div class="drop-down-content">
                                <div class="item" @click="changePwd">
                                    <el-icon class="item-icon"><Lock /></el-icon>
                                    <span class="item-label">{{ $Lang("login.changePassword") }}</span>
                                </div>
                                <div class="item" @click="loginOut">
                                    <el-icon class="item-icon"><SwitchButton /></el-icon>
                                    <span class="item-label">{{ $Lang("login.out") }}</span>
                                </div>
                            </div>
                        </slot>
                        <template #reference>
                            <div class="user-content">
                                <el-avatar style="height: 3.2rem; width: 3.2rem;"icon="UserFilled" />
                                <div class="user-name">{{ userInfo.username }}</div>
                            </div>
                        </template>
                    </el-popover>
                    
                </div>
            </div>
            <router-view />
        </div>
    </div>
    <el-dialog
        v-model="dialogVisible"
        title=""
        :align-center="true"
        width="500"
    >
        <span>{{ $Lang("login.outConfirm") }}</span>
        <template #footer>
            <div class="dialog-footer">
                <el-button @click="dialogVisible = false">{{$Lang("common.cancel")}}</el-button>
                <el-button type="primary" @click="outConfirm">{{$Lang("common.confirm")}}</el-button>
            </div>
        </template>
    </el-dialog>
    <el-dialog v-model="visible" :align-center="true" width="500">
        <template #header="{ close, titleId, titleClass }">
            <div class="my-header">
                <h4 :id="titleId" :class="titleClass">{{$Lang('login.changePassword')}}</h4>
            </div>
        </template>
        <div>
            <el-form 
                ref="registerForm" 
                :model="registerData" 
                :rules="registerRules" 
                class="register-form"
                auto-complete="on" 
                label-position="top"
                >
                <el-form-item prop="password">
                    <el-input
                        :key="passwordType"
                        v-model="registerData.password"
                        :type="passwordType"
                        :placeholder="`${$Lang('login.oldPwd')}`"
                        name="password"
                        auto-complete="on"
                        show-password
                        @keyup.enter.native="registerSubmitForm"
                    />
                </el-form-item>
                <el-form-item prop="newPassword">
                    <el-input
                        :key="passwordType"
                        v-model="registerData.newPassword"
                        :type="passwordType"
                        :placeholder="`${$Lang('login.newPwd')}`"
                        name="newPassword"
                        auto-complete="on"
                        show-password
                        @keyup.enter.native="registerSubmitForm"
                    />
                </el-form-item>
                <el-form-item prop="confirmPassword">
                    <el-input
                        :key="passwordType"
                        v-model="registerData.confirmPassword"
                        :type="passwordType"
                        :placeholder="`${$Lang('login.confirmPwd')}`"
                        name="confirmPassword"
                        auto-complete="on"
                        show-password
                        @keyup.enter.native="registerSubmitForm"
                    />
                </el-form-item>
            </el-form>
           
        </div>
        <template #footer>
            <div class="dialog-footer">
                <el-button @click="visible = false">{{$Lang("common.cancel")}}</el-button>
                <el-button :loading="loading" type="primary" @click.native.prevent="registerSubmitForm">{{$Lang('login.changePassword')}}</el-button>
            </div>
        </template>
    </el-dialog>
</template>

<script setup>
import { ref, onMounted, getCurrentInstance } from 'vue'
import LeftMenu from '@/components/LeftMenu.vue'
import { useRouter } from 'vue-router'
import { localStorageSET, localStorageGET } from '@/utils/local'
import { ElNotification, ElMessage } from 'element-plus'
import { modifyPass } from '../api/index'

import {
    setToken,
} from '../utils/auth.js'

import {
    datediff,
} from '../utils/util.js'

const { appContext } = getCurrentInstance();
const $Lang = appContext.config.globalProperties.$Lang;

let langData = ref({
    en: {
        label: 'English'
    },
    'zh-cn': {
        label: '中文'
    },
    zh: {
        label: '中文-簡體'
    }
})
let langValue = ref("English")


const router = useRouter()
let isCollapse = ref(false)
let dialogVisible = ref(false)
const passwordType = ref('password')
let visible = ref(false)
const loading = ref(false)
const registerForm = ref();
const registerData = ref({ 
    confirmPassword: "",
    newPassword: "",
    password: ""
})
const registerRules = ref({
    password: [
        {
            required: true,
            message: $Lang('appManage.placeholderNormal'), 
            trigger: 'blur',
        },
    ],
    newPassword: [
        {
            required: true,
            message: $Lang('appManage.placeholderNormal'), 
            trigger: 'blur',
        },
    ],
    confirmPassword: [
        {
            required: true,
            message: $Lang('appManage.placeholderNormal'), 
            trigger: 'blur',
        },
    ],
})


const userInfo = ref({
    username : "",
    availTime: ""
})

const isAvaild = ref(false);
onMounted(() => {
    let langVal = localStorageGET('lang')
    langValue.value = langData.value[langVal].label
    userInfo.value = JSON.parse(localStorage.getItem('userInfo'))
    const dateNow = new Date();
    const dateString = userInfo.value.availTime;
    const availDate = new Date(dateString.replace(" ", "T"));
    if (datediff('s', dateNow, availDate) > 0) {
        isAvaild.value = false;
    }else {
        isAvaild.value = true;
    }
})

// 切换菜单栏mod
const menuToggle = () => {
    isCollapse.value = !isCollapse.value
}

// 语言切换
const handleCommand = (value) => {
    localStorageSET({
        "lang": value
    })
    let label = langData.value[value].label
    langValue.value = label
    ElNotification.success({
        title: 'Success',
        message: `${$Lang('languages.alter')}${label}`,
        type: 'success',
    })
// ElNotification.warning({
//                 title: 'Warning',
//                 message: res.message,
//                 type: 'warning',
//             })
//     ElMessage({
//         message: `${$Lang('languages.alter')}${label}`,
//         type: 'success',
//     })
    setTimeout(() => {
        location.reload()
    }, 1200);
    
}

// 退出登录
const loginOut = () => {
    dialogVisible.value = true;
}

// 退出登录-确认
const outConfirm = () => {
    dialogVisible.value = false
    // ElMessage({
    //     message: $Lang('login.outSucceed'),
    //     type: 'success',
    // })
    ElNotification.success({
        title: 'Success',
        message: $Lang('login.outSucceed'),
        type: 'success',
    })
    router.replace('/login')
    setToken('')
    localStorage.setItem('userInfo', '');
}

// 修改密码
const changePwd = () => {
    visible.value = true;
}

const registerSubmitForm = async () => {
    if (!registerForm.value) return
    await registerForm.value.validate((valid, fields) => {
        if (valid) {
            handleLogin();
        } else {
            console.log('error submit!', fields)
        }
    })
}

const handleLogin = () => {

    const params = {
        confirmPassword: registerData.value.confirmPassword,
        newPassword: registerData.value.newPassword,
        password: registerData.value.password
    }
    console.log(registerData, params);
    modifyPass(params).then((res)=>{
        if (res.code == 200) {
            // ElMessage({
            //     message: `${$Lang('login.changeSuccess')}`,
            //     type: 'success',
            // })
            ElNotification.success({
                title: 'Success',
                message: `${$Lang('login.changeSuccess')}`,
                type: 'success',
            })
            setTimeout(() => {
                router.replace('/login')
                setToken('')
                localStorage.setItem('userInfo', '');
            }, 1500);
        }else{
            // ElMessage({
            //     message: res.message,
            //     type: 'warning',
            // })
            ElNotification.error({
                title: 'Error',
                message: res.message,
                type: 'error',
            })
        }
        loading.value = false;
    })
}


</script>

<style lang="scss" scoped>
.drop-down-content {
    padding: 0.5rem 0.8rem;
    .item {
        display: flex;
        align-items: center;
        padding: 0.4rem 0.6rem;
        margin-bottom: 0.5rem;
        &:hover {
            background: rgba(157, 157, 157, 0.2);
            cursor: pointer;
        }
        &:last-child {
            margin-bottom: 0;
        }
        .item-icon {
            margin-right: 0.4rem;
            font-size: 1.6rem;
        }
        .item-label {
            font-size: 1.4rem;
            color: red($color: red);
        }
    }
}
</style>
<style lang="scss" scoped>
.main {
    display: flex;
    min-height: 100vh;
    .main-right {
        flex: 1;
        min-width: 900px;
        display: flex;
        flex-direction: column;
        background-color: rgb(245, 247, 249);
        .header {
            width: 100%;
            /* background: #3665fd; */
            background: #fff;
            padding: 0.5rem 1.5rem;
            display: flex;
            justify-content: space-between;
            flex-shrink: 0;
            height: 5.6rem;
            box-sizing: border-box;
            box-shadow: 0rem 0rem 0.3rem 0.1rem rgba(0, 0, 0, 0.1);
            .menu-btn {
                display: flex;
                align-items: center;
                // color: #fff;
                .el-icon {
                    font-size: 2rem;
                }
                &:hover {
                    cursor: pointer;
                    color: #dee3eb;
                }
            }
            .user-content{
                display: flex;
                align-items: center;
                color: #000;
                cursor: pointer;
                .lang-dropdown{
                    margin-right: 2rem;
                    color: #000;
                    .el-icon{
                        position: relative;
                        top: 2px;
                    }
                    &:hover{
                        cursor: pointer;
                    }
                    .el-dropdown-link:focus-visible{
                        outline: none
                    }
                }
                .user-name{
                    margin-left: 1rem;
                    font-size: 1.6rem;
                    color: #000;
                }
                .avail-time{
                    /* color: #2ABD60;*/
                    color: #000;
                    margin-right: 3rem;
                    font-size: 1.6rem;
                    font-weight: 800;
                    display: flex;
                    align-self: center;
                    .title{
                        color: #000;
                        font-weight: 400;
                        font-size: 1.4rem;
                        line-height: 2.6rem;
                    }
                    .avail-icon{
                        margin-left: .3rem;
                        margin-top: .5rem;
                        font-size: 1.8rem;
                        color: rgb(235, 16, 16)
                    }
                }
            }
        }
    }
}
</style>
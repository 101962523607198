export default {
    common: {
        confirm: "確認",
        cancel: "取消",
        count: "次",
        add: "新增",
        edit: "編輯",
        download: "下載",
        del: "刪除",
        deleteTip: "確認刪除？",
        status: "状态",
        availTime: "套餐到期",
        tokenInvalid: "登入資訊失效~",
        availToolTip: "套餐已過期，請聯絡代理",
    },
    login: {
        out: "退出登錄",
        outConfirm: "確認退出登錄嗎？",
        outSucceed: "退出登錄成功",
        loginTitle: "使用者登入",
        userName: "使用者名稱",
        password: "密碼",
        login: "登錄",
        regisiterAccount: "註冊帳號",
        regisiterSuccess: "註冊成功",
        regisiter: "註冊",
        changePassword: "修改密碼",
        oldPwd: "舊密碼",
        newPwd: "新密碼",
        confirmPwd: "再次輸入新密碼",
        changeSuccess: "修改成功",
    },
    home: {
        region: "國家地區",
        openCount: "頁面打開次數",
        downloadCount: "下載次數",
        deliveryTime: "投放時間",
        total: "總計"
    },
    user: {
        userManager: "用户管理",
        user: "用户",
    },
    fundWallet: {
        me: "我的錢包",
        recharge: "充值",
        expireDate: "到期時間",
        renew: "續費",
    },
    menu: {
        statisticalAnalysis: "統計分析",
        home: "首頁",
        popularize: "推廣",
        develop: "開發中心",
        finance: "財務"
    },
    menuSub: {
        documentCenter: "文檔中心",
        fundWallet: "資金錢包",
        appManage: "APP 管理",
        pixelManage: "像素管理"
    },
    languages:{
        "alter": "語言切換至",
        "zh-cn": "中文（簡體）",
        "zh": "中文（繁體）",
        "en": "英語",
        "id": "印尼語",
        "ja": "日語",
        "fr": "法語",
        "vi": "越南語",
        "ru": "俄語",
        "de": "德語",
        "es": "西班牙語"
    },
    preview: {
        download: "下載",
        comment: "評論",
        age: "年齡",
        rank: "排行榜",
        developer: "開發者",
        unitAge: "歲",
        newFunction: "新功能",
        versionHistory: "版本歷史記錄",
        ver: "版本",
        preview: "預覽",
        scoreAndComment: "評分和評論",
        fullScore: "滿分",
        clickToScore: "評論",
        information: "信息",
        developer: "開發者",
        category: "類別",
        compatibility: "相容性",
        size: "大小",
        language: "語言",
        ageGrading: "年齡分級",
        offer: "提供者",
        copyright: "版權",
    },
    appManage: {
        appManager: "管理",
        add: "新增",
        edit: "編輯",
        download: "下載",
        del: "刪除",
        name: "名稱",
        icon: "圖標",
        share: "推廣頁",
        pageLanguage: "推廣頁語言",
        remark: "備註",
        share: "分享",
        operation: "操作",
        baseMsg: "打包信息",
        companyName: "公司名稱",
        ageLimit: "年齡限制",
        commentNumber: "評論數",
        packSize: "包大小",
        category: "類型",
        rank: "排名",
        version: "版本號",
        updateTime: "更新時間",
        updateConten: "更新內容",
        descript: "簡介",
        previeTip: "請上傳App簡介",
        scoreSet: "打分設置",
        finalScore: "最終得分",
        commentSet: "評論設置",
        addComment: "添加評論",
        cancel: "取消",
        nextStep: "下一步",
        upStep: "上一步",
        package: "打包",
        address: "地址",
        shopMsg: "商店資訊",
        nameDesc: "名稱描述",
        imgTip: "支持 png/jpg/jpeg 格式的圖片，圖片最大2MB",
        imgErrorTip1: "圖片大小不能超過 2MB!",
        imgErrorTip2: "請上傳 jpg/png/jpeg 類型的文件!",
        language: "語言",
        languageTip: "請選擇APP內支持語言",
        compatibility: "兼容性",
        compatibilityTip: "請選擇APP兼容性",
        copyright: "版權",
        detailPics: "詳細圖片",
        score: "分數1-5",
        date: "日期",
        content: "內容",
        placeholderTip1: "請輸入App名字",
        placeholderTip2: "請上傳APP圖標",
        placeholderTip3: "請輸入APP名稱描述",
        placeholderTip4: "請輸入公司名字",
        placeholderTip5: "請輸入APP年齡限制",
        placeholderTip6: "請輸入App排名",
        placeholderTip7: "請輸入App包大小",
        placeholderTip8: "請輸入App分類",
        placeholderTip9: "請選擇App語言",
        placeholderTip10: "請輸入App版本號",
        placeholderTip11: "請輸入版本更新時間",
        placeholderTip12: "請輸入版本更新內容",
        placeholderTip13: "請輸入App描述",
        placeholderTip14: "請輸入版權信息",
        placeholderTip15: "請至少選擇一個",
        placeholderTip16: "請輸入打包地址",
        placeholderTip17: "請輸入App評論數",
        finishPack: "打包完成！",
        confirm: "確認",
        success: "成功",
        tip: "提示",
        deleteTip: "確認刪除該APP?",
        packing: "正在打包...",
        placeholderNormal: "請輸入",
        deleteCommmentTip: "確認刪除該評論?",
        digitsPlaceholder: "請輸入數字",
        digitsRangePlaceholder: "請輸入1-5之間的分數",
        addAppTip: "每個帳號只能建立一個APP",
    },
    pixelManage: {
        adPixel : "廣告像素",
        pixelName : "像素名稱"
    },
    documentCenter: {
        documentCenter: "文件中心"
    }
}

import { createApp } from 'vue'

import App from './App.vue'
import router from './router'
import store from './store'

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import './assets/main.css'
import { getValueFromObject } from '@/utils/util'

const app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

// 语言
import langData from '@/assets/lang'
app.config.globalProperties.$Lang = (value) => {
    let langVal = document.documentElement.getAttribute("lang")
    return getValueFromObject(langData[langVal], value)
}

app.use(ElementPlus)

app.use(store)
app.use(router)
app.mount('#app')

<template>
    <div :style="{width: isCollapse ? 'calc(var(--el-menu-icon-width) + var(--el-menu-base-level-padding)* 2)': '200px', 'flex-shrink': 0}">
        <!-- active-text-color="#0359D8"
            background-color="#fff"
            text-color="#606266" -->
        <el-menu
            active-text-color="#fff"
            background-color="#001428"
            text-color="#999"
            class="el-menu-vertical"
            :collapse="isCollapse"
            :router="true"
            :default-active="menuActive"
            @select="selectChange"
            :style="{ 'position': 'fixed', 'z-index': 99, height: '100%'}"
        >
            <el-menu-item :index="null" style="height: 56px;">
                <img :style="{width: isCollapse ? '2.3rem' : '100%'}" :src="isCollapse ? logoMinImg : logoImg" alt="logo" />
            </el-menu-item>
            <el-menu-item index="/user">
                <el-icon><Iphone /></el-icon>
                <span class="menu-name">{{ $Lang("user.userManager") }}</span>
            </el-menu-item>
            <el-menu-item index="/home">
                <el-icon><House /></el-icon>
                <template #title>
                    <span class="menu-name">{{ $Lang("menu.statisticalAnalysis") }}</span>
                </template>
            </el-menu-item>
            
            <!-- <el-sub-menu index="2">
                <template #title>
                    <el-icon><Histogram /></el-icon>
                    <span>{{ $Lang("menu.popularize") }}</span>
                </template> -->

                
<!-- 
                <el-menu-item index="/popularize/pixel">
                    <el-icon><Document /></el-icon>{{ $Lang("menuSub.pixelManage") }}
                </el-menu-item> -->
            <!-- </el-sub-menu> -->

            <!-- <el-sub-menu index="3">
                <template #title>
                    <el-icon><SetUp /></el-icon>
                    <span>{{ $Lang("menu.develop") }}</span>
                </template>

                <el-menu-item index="/develop/documentCenter">
                    <el-icon><Document /></el-icon>
                    <span class="menu-name">{{ $Lang("menuSub.documentCenter") }}</span>
                </el-menu-item>
            </el-sub-menu> -->
<!-- 
            <el-sub-menu index="4">
                <template #title>
                    <el-icon><Coin /></el-icon>
                    <span>{{ $Lang("menu.finance") }}</span>
                </template>

                <el-menu-item index="/finance/fundWallet">
                    <el-icon><Wallet /></el-icon>{{ $Lang("menuSub.fundWallet") }}
                </el-menu-item>
            </el-sub-menu> -->
        </el-menu>
    </div>
</template>
  
<script setup>
import { onMounted, ref } from 'vue'
import logoImg from '@/assets/images/logo.png'
import logoMinImg from '@/assets/images/logo-min.png'
import { localStorageSET, localStorageGET } from '@/utils/local'

const props = defineProps(['isCollapse'])
const menuActive = ref(null)

onMounted(() => {
    menuInit()
})

// 菜单-切换
const selectChange = (index) => {
    localStorageSET({
        menu: index
    })
}

// 初始化菜单
const menuInit = () => {
    let menu = localStorageGET("menu")

    if(!menu){
        menu = '/home'
    }
    
    menuActive.value = menu
}

</script>
  
<style scoped>
.el-menu-vertical {
    /* background: #000; */
    /* color: #fff !important; */
    border-right: none !important;
    box-shadow: .1rem .0rem .0rem .15rem rgba(168, 168, 168, .1)
}
.el-menu-vertical:not(.el-menu--collapse) {
    width: 200px;
}

::v-deep .el-sub-menu .el-menu-item {
    height: 4.2rem;
    line-height: 4.2rem;
}

::v-deep .el-menu-item{
    padding: 0 2rem;
    height: 4.2rem;
    line-height: 4.2rem;
}

::v-deep .el-menu-item.is-active {
    font-weight: 600;
    /* background-color: #eff6ff; */
    /* background-color: #67c23a; rgba(64,158,255, 0.9); */
    border-radius: .2rem;
}

::v-deep .el-menu-item.is-active::before {
    z-index: auto;
    content: "";
    background-color: #2d8cf0;
    position: absolute;
    left: 8px;
    right: 8px;
    top: 0;
    bottom: 0;
    pointer-events: none;
    border-radius: .3rem;
    transition: background-color .3s cubic-bezier(.4, 0, .2, 1)
}

.menu-name{
    z-index: 1;
}

</style>
  
<template>
  <router-view/>
</template>

<script setup>
import { getCurrentInstance, onMounted } from 'vue';
import {
    verifyToken
} from '@/api/index'
import { useRouter } from 'vue-router'
import {
    setToken,
} from '@/utils/auth.js'

const router = useRouter()

if(router.options.history.location !== '/login'){
    verifyToken().then((res)=>{
        console.log("验证token有效性", res);
        if (res.code != 200) {
            setToken('')
            localStorage.setItem('userInfo', '');
            router.push({ path: '/' })
        }
    })
}

</script>

<style lang="scss"></style>

import {
    createRouter,
    createWebHashHistory
} from 'vue-router'

import { ElMessage } from "element-plus";
import Main from '@/views/Main.vue'

const routes = [
    {
        path: '/',
        redirect: '/user',
        component: Main,
        children: [{
            path: 'user',
            component: () => import('@/views/userList/index.vue'),
        }]
    },
    {
        path: '/',
        redirect: '/home',
        component: Main,
        children: [{
            path: 'home',
            component: () => import('@/views/home/index.vue'),
        }]
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/login.vue')
    },
    // 推广
    {
        path: '/popularize',
        component: Main,
        children: [
            {
                path: 'user',
                component: () => import('@/views/popularize/appManage/index.vue'),
            },
            {
                path: 'preview',
                component: () => import('@/views/popularize/appManage/preview.vue'),
            },
            {
                path: 'pixel',
                component: () => import('@/views/popularize/appManage/pixel.vue'),
            }
        ]
    },
    // 财务
    {
        path: '/finance',
        component: Main,
        children: [{
            path: 'fundWallet',
            component: () => import('@/views/finance/fundWallet/index.vue'),
        }]
    }
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})
/**
 * 全局前置路由守卫，每一次路由跳转前都进入这个 beforeEach 函数
 */
router.beforeEach((to, from, next) => {
    if (to.path == '/login') {
        // 登录或者注册才可以往下进行
        next();
    } else {
        // 获取 token
        const token = localStorage.getItem('token'); 
        // token 不存在
        if (token === null || token === '') {
            // ElMessage.error('登录信息失效~');
            next('/login');
        } else {
            next();
        }
    }
});


export default router